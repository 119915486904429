// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import theme from './theme'

const rootReducer = combineReducers({
  auth,
  theme,
})

export default rootReducer
